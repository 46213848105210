import React from 'react';
import cLanguage from '../assets//skills/cLanguage.png';
import Csharp from '../assets//skills/Csharp.png';
import css from '../assets//skills/css.png';
import django from '../assets//skills/django.png';
import flask from '../assets//skills/flask.png';
import github from '../assets//skills/github.png';
import htmlpicture from '../assets//skills/html.png';
import javascript from '../assets//skills/javascript.png';
import linux from '../assets//skills/linux.png';
import Python from '../assets//skills/Python.png';
import reactImage from '../assets//skills/react.png';
import tailwind from '../assets//skills/tailwind.png';
import bootstrap from '../assets//skills/Bootstrap.png';
import mysqldatabase from '../assets//skills/MySQL.png';
import figmaImage from '../assets/skills/figma.png';
import dockerImage from '../assets/skills/docker.png';
import mongodbImage from '../assets/skills/mongodb.png';
import restapiImage from '../assets/skills/restfulapi.png';

const Experience = () => {
    const techs = [
        {
            id: 1,
            src: htmlpicture,
            title: "HTML",
            style: "shadow-orange-500",
        },
        {
            id: 2,
            src: css,
            title: "CSS",
            style: "shadow-blue-500",
        },
        {
            id: 3,
            src: javascript,
            title: "JavaScript",
            style: "shadow-yellow-500",
        },
        {
            id: 4,
            src: reactImage,
            title: "React",
            style: "shadow-sky-400",
        },
        {
            id: 5,
            src: tailwind,
            title: "Tailwind",
            style: "shadow-sky-400",
        },
        {
            id: 6,
            src: Csharp,
            title: "C#",
            style: "shadow-fuchsia-950",
        },
        {
            id: 7,
            src: cLanguage,
            title: "C Language",
            style: "shadow-blue-800",
        },
        {
            id: 8,
            src: github,
            title: "GitHub",
            style: "shadow-gray-400",
        },
        {
            id: 9,
            src: django,
            title: "Django",
            style: "shadow-green-800",
        },
        {
            id: 10,
            src: flask,
            title: "Flask",
            style: "shadow-gray-400",
        },
        {
            id: 11,
            src: linux,
            title: "Linux Command",
            style: "shadow-orange-500",
        },
        {
            id: 12,
            src: Python,
            title: "Python",
            style: "shadow-yellow-500",
        },
        {
            id: 13,
            src: bootstrap,
            title: "Bootstrap",
            style: "shadow-violet-700",
        },
        {
            id: 14,
            src: mysqldatabase,
            title: "MYSQL",
            style: "shadow-orange-500",
        },
        {
            id: 15,
            src: figmaImage,
            title: "FIGMA",
            style: "shadow-red-500",
        },
        {
            id: 16,
            src: restapiImage,
            title: "RESTFUL API",
            style: "shadow-blue-600",
        },  
        {
            id: 17,
            src: mongodbImage,
            title: "MONGO DB",
            style: "shadow-green-500",
        }, 
        {
            id: 18,
            src: dockerImage,
            title: "DOCKER",
            style: "shadow-blue-600",
        },
    ];


    return (
        <div
            name="experience"
            className="bg-gradient-to-b from-gray-800 to-black md:h-screen h-max py-4"
        >
            <br /><br />
            <div className="max-w-screen-lg mx-auto mt-3 p-1 flex flex-col justify-center w-full h-full text-white">
                <div>
                    <p className="text-4xl font-bold border-b-4 border-gray-500 inline">
                        Experience
                    </p>
                    <p className="py-2">These are the technologies I've worked with</p>
                </div>

                <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 text-center px-12 sm:px-0 md:px-8">
                    {techs.map(({ id, src, title, style }) => (
                        <div
                            key={id}
                            className={`shadow-md hover:scale-105 duration-500 rounded-lg ${style}`}
                        >
                            <img src={src} alt={title} className="w-20 mx-auto h-20" />
                            <p className="my-2">{title}</p>
                        </div>
                    ))}
                </div>
            </div>
            <br /><br />
        </div>
    );
};

export default Experience;