import React from 'react';
import { Link } from "react-scroll";

const About = () => {
  return (
    <div name="about" className='w-full h-screen bg-gradient-to-b from-gray-800 to-black text-white py-1 sm:py-3'>
       <div className='max-w-screen-lg px-4 mx-auto flex flex-col justify-center h-full'>
        <div className='py-5'>
          <p className='text-4xl font-bold inline border-b-4 border-gray-500'>About</p>
        </div>
        <p className='font-nunito'>
          Hello, I'm <strong>Tshupane Patrick Morake</strong>. With over 2 years of hands-on experience in software development and design,
          along with my role as a Technical Assistant and Project Coordinator in the telecommunications industry, I bring a unique blend of technical 
          expertise and managerial insight to the table.
          <br /><br />
          In the dynamic world of software development, I have honed my skills and cultivated a passion for crafting innovative 
          web applications. My journey has led me to specialize in technologies I have mentioned 
          <Link to="home" smooth duration={500} className='text-blue-500 font-bold italic underline mx-1'>above</Link>, where 
          I thrive on transforming digital ideas into reality.
        </p>
        <br />
        <p className='font-nunito'>Having navigated the complexities of technical assistance and project coordination in the telecommunications sector, I have gained a deep understanding 
          of how technology can meet and enhance business needs. My commitment to excellence and collaboration has been 
          has consistently driven my professional journey.
          <br /><br />
          I am enthusiastic about the limitless possibilities in the ever-evolving tech landscape and am always eager to embrace new challenges. 
          Let's create something extraordinary together!</p>
      </div>
    </div>
  );
};

export default About;
